import { Fragment, useEffect, useState } from "react";

import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import EcnButton from "../StyledComponents/EcnButton";
import MobileInput from "./Sub/MobileInput";
import MobileVerification from "./Sub/MobileVerification";
import SuccessStep from "./Sub/SuccessStep";
import AuthAppQr from "./Sub/AuthAppQr";
import AuthAppVerification from "./Sub/AuthAppVerification";
import SetupAuthApp from "./Sub/SetupAuthApp";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { verifyMobileReset } from "../../redux/slices/tfaSlices/tfaVerifyMobileSlice";
import { verifyAuthAppReset } from "../../redux/slices/tfaSlices/tfaVerifyAuthAppSlice";
import { tfaGenerateQr } from "../../redux/slices/tfaSlices/tfaGenerateQrSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 10,
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E7E7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  "&.MuiLinearProgress-determinate": {
    backgroundColor: "#E9E7E7",
  },
}));

interface FlowProps {
  setSelectedFlow: React.Dispatch<React.SetStateAction<string>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const AuthAppWithSmsFlow = ({ setSelectedFlow, setActiveStep }: FlowProps) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.userDetails);

  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");

  useEffect(() => {
    dispatch(tfaGenerateQr(userInfo?.email));
  }, []);

  const leaveFlowHandler = () => {
    setSelectedFlow("");
    setActiveStep(0);
    dispatch(verifyMobileReset());
    dispatch(verifyAuthAppReset());
  };

  return (
    <div className="auth-app-flow">
      <div className="card">
        {step <= 5 && (
          <Fragment>
            <BorderLinearProgress
              variant="determinate"
              color="primary"
              value={(step / 5) * 100}
              className="progress-bar"
            />
            <div className="head">
              <EcnButton
                className="btn-text back-btn"
                onClick={leaveFlowHandler}
                startIcon={
                  <Icon
                    icon="lucide:arrow-left"
                    height={24}
                    width={24}
                    color="rgba(33, 33, 33, 0.8)"
                  />
                }
              >
                Authenticator App
              </EcnButton>
              <p className="step-count">{step}/5</p>
            </div>
          </Fragment>
        )}

        {step === 1 ? (
          <MobileInput mobile={mobile} setMobile={setMobile} setStep={setStep} />
        ) : step === 2 ? (
          <MobileVerification mobile={mobile} setStep={setStep} midStep={true} />
        ) : step === 3 ? (
          <SetupAuthApp setStep={setStep} />
        ) : step === 4 ? (
          <AuthAppQr setStep={setStep} />
        ) : step === 5 ? (
          <AuthAppVerification setStep={setStep} />
        ) : (
          <SuccessStep />
        )}
      </div>
    </div>
  );
};

export default AuthAppWithSmsFlow;
