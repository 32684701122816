import React, { Fragment, useEffect, useState } from "react";
import EcnButton from "../../StyledComponents/EcnButton";
import EcnInput from "../../StyledComponents/EcnInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { tfaPassKeyNameChange } from "../../../redux/slices/tfaSlices/tfaPasskeyNameChangeSlice";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const PasskeySuccessStep = ({ credId }: { credId: string }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect-url");

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { tfaPasskeyNameChangeResponse } = useAppSelector(
    (state) => state.tfaPasskeyNameChange
  );

  const [passkeyName, setPasskeyName] = useState("");
  const [nameUpdated, setNameUpdated] = useState(false);

  useEffect(() => {
    if (tfaPasskeyNameChangeResponse) {
      setNameUpdated(true);
    }
  }, [tfaPasskeyNameChangeResponse]);

  const enableOtherWaysHandler = () => {
    window.location.reload();
  };

  const navigateHandler = () => {
    if (redirectUrl) window.location.href = redirectUrl;
    else navigate("/dashboard");
  };

  const passkeyNameChangeHandler = () => {
    dispatch(
      tfaPassKeyNameChange({
        credId: credId,
        name: passkeyName,
        email: userInfo?.email,
      })
    );
  };
  return (
    <div className="success-step">
      <img src={IMG_BASE + "/enterprise/success_1.gif"} alt="" />
      {nameUpdated ? (
        <p className="success-msg">
          Passkey "{passkeyName}" successfully added to your account and can be used
          to sign in!
        </p>
      ) : (
        <h3>Successfully added Passkey</h3>
      )}

      {nameUpdated ? (
        <div className="btn-wrapper">
          <div />
          <EcnButton
            className="btn-contained passkey_done-btn"
            variant="contained"
            onClick={navigateHandler}
          >
            Done
          </EcnButton>
          <div />
        </div>
      ) : (
        <Fragment>
          <div className="divider passkey-divider" />

          <div className="passkey-data">
            <p className="heading">Passkey Nickname</p>
            <p className="sub">
              This passkey can work across multiple devices - pick a nickname that
              will help you identify it later
            </p>

            <p className="sub label">For example, the name of your device</p>
            <EcnInput
              className="ecn-input passkey-input"
              value={passkeyName}
              onChange={(e) => setPasskeyName(e.target.value)}
            />
          </div>

          <div className="btn-wrapper">
            <EcnButton
              className="btn-text back-btn"
              onClick={enableOtherWaysHandler}
            >
              Enable other ways
            </EcnButton>
            <EcnButton
              className="btn-contained redirect-btn"
              variant="contained"
              disabled={!passkeyName}
              onClick={passkeyNameChangeHandler}
            >
              Confirm
            </EcnButton>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PasskeySuccessStep;
