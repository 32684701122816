import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaVerifyMobile = createAsyncThunk(
  "tfaVerifyMobile",
  async (contactInfo: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + AUTH_BASE}/v1/verifyMobileNumber`,
        {
          enterprise: true,
          email: contactInfo.email,
          session_id: contactInfo.session_id,
          otp: contactInfo.otp,
        }
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tfaVerifyMobileSlice = createSlice({
  name: "tfaVerifyMobile",
  initialState: {
    loading: false,
    tfaMobileVerifyResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {
    verifyMobileReset: (state) => {
      state.loading = false;
      state.tfaMobileVerifyResponse = null;
      state.errorData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tfaVerifyMobile.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.tfaMobileVerifyResponse = null;
    });
    builder.addCase(tfaVerifyMobile.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaMobileVerifyResponse = action.payload;
    });
    builder.addCase(tfaVerifyMobile.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export const { verifyMobileReset } = tfaVerifyMobileSlice.actions;

export default tfaVerifyMobileSlice.reducer;
