import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const acceptInvite = createAsyncThunk("acceptInvite", async (user: any) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + ADMIN_BASE}/acceptInvite`,
    user
  );
  return data;
});

const acceptInviteSlice = createSlice({
  name: "acceptInvite",
  initialState: {
    loading: false,
    acceptInviteData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(acceptInvite.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(acceptInvite.fulfilled, (state, action) => {
      state.loading = false;
      state.acceptInviteData = action.payload;
    });
    builder.addCase(acceptInvite.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default acceptInviteSlice.reducer;
