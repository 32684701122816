import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { RedirectForNetwork } from "../../helpers/TempData";
const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const Header = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const RedirectForNetwork = (BASE: any) => {
    if (
      BASE === "https://api.onefinnet.com" ||
      BASE === "https://api.elevatecareernetwork.com"
    ) {
      window.location.href = "https://onefinnet.com/";
    } else if (BASE === "https://api.test.ecndev.io") {
      window.location.href = "https://www.test.ecndev.io/";
    } else {
      window.location.href = "https://www.dev.ecndev.io/";
    }
  };

  console.log("token", token);
  return (
    <div className="Header">
      <img
        className="logo"
        src="/B_ELEVATE 2.0.png"
        alt=""
        onClick={() => RedirectForNetwork(GATEWAY_BASE)}
      />
      {(window.location.pathname !== "/signup/details" && token === null) && (
        <Button
          className="buttons"
          onClick={() => RedirectForNetwork(GATEWAY_BASE)}
        >
          <Icon icon="lucide:home" /> Home
        </Button>
      )}
    </div>
  );
};

export default Header;
