import React, { useState } from "react";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import EcnInput from "../StyledComponents/EcnInput";
import { CircularProgress, InputAdornment } from "@mui/material";
import EcnPopover from "../StyledComponents/EcnPopover";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userForgotPassword } from "../../redux/slices/userSlices/userForgotPasswordSlice";
import { correctEmail } from "../../helpers/Validations/BasicValidations";

const REDIRECT_URL = process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_URL;

const EmailStep = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");

  const { forgotPasswordResponse, loading } = useAppSelector(
    (state) => state.forgotPassword
  );

  const [email, setEmail] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const backHandler = () => {
    navigate(`/signin?origin=${origin}`);
  };

  const sendEmailHandler = () => {
    dispatch(
      userForgotPassword({
        email: email,
        redirect_url: REDIRECT_URL,
        next_redirect: origin,
        enterprise: true,
      })
    );
  };

  return (
    <div className="email-step">
      <EcnButton
        color="secondary"
        className="btn-text signin-back_btn"
        onClick={backHandler}
        startIcon={
          <Icon
            icon="lucide:arrow-left"
            height={20}
            width={20}
            color="rgba(33, 33, 33, 0.8)"
          />
        }
      >
        Forgot Password
      </EcnButton>
      {/* <p className="sub">We need your email to send you password reset link.</p> */}
      <div className="field">
        <div className="head">
          <label htmlFor="">Email</label>

          {/* <Icon
            aria-owns={open ? "email-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            icon="lucide:info"
            height={24}
            width={24}
            color="black"
          /> */}
        </div>
        <EcnInput
          placeholder="Enter email address"
          className="ecn-input signin-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="lucide:mail" height={20} width={20} color="black" />
              </InputAdornment>
            ),
          }}
        />
        {forgotPasswordResponse && (
          <p className="success">
            <Icon icon="charm:tick" height={16} width={16} color="#027A48" />{" "}
            Password reset link sent to email address
          </p>
        )}
      </div>
      <div className="action-wrapper">
        <EcnButton
          className="btn-contained "
          variant="contained"
          disabled={email === "" || !correctEmail.test(email) || loading}
          onClick={sendEmailHandler}
        >
          {forgotPasswordResponse ? "Resend" : "Next"}{" "}
          <Icon
            icon="lucide:arrow-right"
            className="right-icon"
            width={18}
            height={18}
            style={{ marginTop: "5px", marginLeft: "5px" }}
          />
          {loading && (
            <CircularProgress
              style={{ color: "#fff", marginLeft: "10px" }}
              size={15}
            />
          )}
        </EcnButton>
      </div>
      <EcnPopover
        id="email-popover"
        open={open}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: "none",
          marginLeft: "20px",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            sx: { borderRadius: "6px", boxShadow: "none", overflow: "visible" },
          },
        }}
      >
        <p style={{ fontSize: "14px", lineHeight: "24px", color: "#212121" }}>
          <span style={{ fontWeight: "500" }}>Note :</span> You will receive the
          email if you have entered a valid email address
        </p>
      </EcnPopover>
    </div>
  );
};

export default EmailStep;
