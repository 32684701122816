import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const userSignin = createAsyncThunk(
  "userSignin",
  async (user: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + AUTH_BASE}/v1/keycloak/login`,
        user
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSigninSlice = createSlice({
  name: "userSignin",
  initialState: {
    loading: false,
    signinResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.signinResponse = null;
      state.errorData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userSignin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userSignin.fulfilled, (state, action) => {
      state.loading = false;
      state.signinResponse = action.payload;
    });
    builder.addCase(userSignin.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export const { reset } = userSigninSlice.actions;

export default userSigninSlice.reducer;
