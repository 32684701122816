import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import EcnButton from "../StyledComponents/EcnButton";
import { InputAdornment, IconButton, Alert, AlertTitle } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import EcnInput from "../StyledComponents/EcnInput";

import {
  hasNumber,
  hasEightCharacters,
  hasSpecialCharacter,
  hasUpperAndLowerCase,
  strongPassword,
} from "../../helpers/Validations/BasicValidations";
import EcnPopover from "../StyledComponents/EcnPopover";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userResetPassword } from "../../redux/slices/userSlices/userResetPasswordSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnSnackbar from "../StyledComponents/EcnSnackbar";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 10,
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E7E7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  "&.MuiLinearProgress-determinate": {
    backgroundColor: "#E9E7E7",
  },
}));

const CheckPassword: React.FC<{
  setPassword: any;
  setConfirmPassword: any;
  password: any;
  confirmPassword: any;
  setIsStrong: any;
}> = ({
  setPassword,
  setConfirmPassword,
  password,
  confirmPassword,
  setIsStrong,
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    num: 0,
    text: "",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (password) {
      let strengthCount = 0;

      if (hasEightCharacters(password)) strengthCount++;
      if (hasNumber(password)) strengthCount++;
      if (hasSpecialCharacter(password)) strengthCount++;
      if (hasUpperAndLowerCase(password)) strengthCount++;

      switch (strengthCount) {
        case 1:
          setPasswordStrength({ num: 1, text: "Bad" });
          setIsStrong(1);
          break;
        case 2:
          setPasswordStrength({ num: 2, text: "Weak" });
          setIsStrong(2);
          break;
        case 3:
          setPasswordStrength({ num: 3, text: "Intermediate" });
          setIsStrong(3);
          break;
        case 4:
          setPasswordStrength({ num: 4, text: "Very Strong" });
          setIsStrong(4);
          break;
        default:
          setPasswordStrength({ num: 0, text: "" });
          setIsStrong(0);
          break;
      }
    }
  }, [password]);

  const handlePopoverOpen = (event: React.ChangeEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    handlePopoverOpen(e);
  };

  const mobilePasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const confirmPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (passwordStrength.num === 4 && e.target.value.length > 0)
      handlePopoverClose();
  };

  return (
    <div className="password-step">
      <div className="field">
        <label htmlFor="">Password</label>

        <EcnInput
          aria-owns={open ? "password-popover" : undefined}
          aria-haspopup="true"
          type={showPass ? "text" : "password"}
          placeholder="Enter password"
          className="ecn-input signin-input password-desktop"
          value={password}
          onChange={passwordHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon="lucide:lock-keyhole"
                  height={20}
                  width={20}
                  color="black"
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPass(!showPass)}>
                  <Icon
                    icon={showPass ? "lucide:eye" : "lucide:eye-off"}
                    height={24}
                    width={24}
                    color="black"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <EcnInput
          aria-owns={open ? "password-popover" : undefined}
          aria-haspopup="true"
          type={showPass ? "text" : "password"}
          placeholder="Enter password"
          className="ecn-input signin-input  password-mobile"
          value={password}
          onChange={mobilePasswordHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon="lucide:lock-keyhole"
                  height={20}
                  width={20}
                  color="black"
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPass(!showPass)}>
                  <Icon
                    icon={showPass ? "lucide:eye" : "lucide:eye-off"}
                    height={24}
                    width={24}
                    color="black"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        {password !== "" ? (
          <div style={{ textAlign: "right" }}>
            <BorderLinearProgress
              variant="determinate"
              color={
                [1, 2].includes(passwordStrength.num)
                  ? "error"
                  : passwordStrength.num === 3
                  ? "warning"
                  : "success"
              }
              value={(passwordStrength.num / 4) * 100}
            />
            <p
              style={{
                color: [1, 2].includes(passwordStrength.num)
                  ? "#ba0000"
                  : passwordStrength.num === 3
                  ? "#fe9800"
                  : "#027A48",
                fontSize: "14px",
                lineHeight: "24px",
              }}
            >
              {passwordStrength.text}
            </p>
          </div>
        ) : null}
      </div>
      <div
        className="field re-enter-password"
        style={{ marginTop: passwordStrength.num === 0 ? 9 : 0 }}
      >
        <label htmlFor="">Re-Enter Password</label>

        <EcnInput
          type={showConfirmPass ? "text" : "password"}
          placeholder="Enter password"
          className="ecn-input signin-input"
          value={confirmPassword}
          onChange={confirmPasswordHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon
                  icon="lucide:lock-keyhole"
                  height={20}
                  width={20}
                  color="black"
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPass(!showConfirmPass)}
                >
                  <Icon
                    icon={showConfirmPass ? "lucide:eye" : "lucide:eye-off"}
                    height={24}
                    width={24}
                    color="black"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {confirmPassword !== "" && confirmPassword !== password ? (
          <p className="error-password">
            <Icon icon="lucide:x" /> Password doesn’t match
          </p>
        ) : (
          confirmPassword !== "" &&
          password !== "" && (
            <p className="match-password">
              <Icon icon="charm:tick" /> Password match
            </p>
          )
        )}
      </div>

      <EcnPopover
        id="password-popover"
        open={open}
        anchorEl={anchorEl}
        sx={{
          marginLeft: "85px",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            sx: { borderRadius: "6px", boxShadow: "none", overflow: "visible" },
          },
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <p
          style={{
            fontSize: "14px",
            color: "#212121",
            lineHeight: "26px",
            marginBottom: "6px",
          }}
        >
          Password must have:
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasEightCharacters(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasEightCharacters(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasEightCharacters(password) ? "#027A48" : "#ba0000"}
          />
          At least 8 characters
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasUpperAndLowerCase(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasUpperAndLowerCase(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasUpperAndLowerCase(password) ? "#027A48" : "#ba0000"}
          />
          Uppercase and lowercase character
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasSpecialCharacter(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasSpecialCharacter(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasSpecialCharacter(password) ? "#027A48" : "#ba0000"}
          />
          One special character (@!#$%)
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasNumber(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasNumber(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasNumber(password) ? "#027A48" : "#ba0000"}
          />
          One Number (1-9)
        </p>
      </EcnPopover>
    </div>
  );
};

export default CheckPassword;

const popoverOption = {
  fontSize: "14px",
  lineHeight: "26px",
  display: "flex",
  alignItems: "center",
  gap: "4px",
};
