import { useEffect, useState } from "react";
import layoutOne from "../layouts/layoutOne";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { tfaStatus } from "../redux/slices/tfaSlices/tfaStatusSlice";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";

import { Icon } from "@iconify/react";

import EcnButton from "../components/StyledComponents/EcnButton";

import AuthAppFlow from "../components/Tfa/AuthAppFlow";
import AuthAppWithSmsFlow from "../components/Tfa/AuthAppWithSmsFlow";
import SmsFlow from "../components/Tfa/SmsFlow";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PassKeyFlow from "../components/Tfa/PassKeyFlow";

const TwoFactorAuthentication = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect-url");

  const { tfaResponse, loading: tfaLoading } = useAppSelector(
    (state) => state.userTfaStatus
  );
  const {
    userInfo,
    loading: userLoading,
    error,
  } = useAppSelector((state) => state.userDetails);

  const [selectedFlow, setSelectedFlow] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    dispatch(userDetails());
  }, []);

  useEffect(() => {
    if (userInfo && !tfaResponse) dispatch(tfaStatus(userInfo?.email));
    else if (!userInfo && !userLoading && error && !tfaResponse) {
      if (redirectUrl) navigate("/signin?origin=" + redirectUrl);
      else navigate("/signin");
    }
  }, [userInfo, error, userLoading, tfaResponse]);

  const stepSelectHandler = (flow: string) => {
    setSelectedFlow(flow);
  };

  const stepInitHandler = () => {
    if (selectedFlow === "sms") {
      setActiveStep(1);
    } else if (selectedFlow === "auth-app" && tfaResponse?.mobile_number_verified) {
      setActiveStep(2);
    } else if (selectedFlow === "auth-app" && !tfaResponse?.mobile_number_verified) {
      setActiveStep(3);
    } else if (selectedFlow === "passkey") {
      setActiveStep(4);
    }
  };

  const navigateHandler = () => {
    if (redirectUrl) window.location.href = redirectUrl;
    else navigate("/dashboard");
  };

  if (tfaLoading || userLoading)
    return (
      <div className="forgot-password">
        <CircularProgress />
      </div>
    );

  return (
    <div className="tfa">
      <div className="container">
        <img className="ecn-logo" src="/one_logo.png" alt="Elevate" />
        {activeStep === 0 ? (
          <div className="selection-step">
            <div className="card">
              <h2>Enable Two-Factor Authentication</h2>

              <div className="flow-list">
                <div
                  className={`flow-card ${
                    tfaResponse?.two_factor_auth_enabled &&
                    tfaResponse?.mobile_number_verified
                      ? "disabled"
                      : ""
                  }`}
                  style={selectedFlow == "sms" ? { borderColor: "#0034bb" } : {}}
                  onClick={() => stepSelectHandler("sms")}
                >
                  <Icon
                    icon="lucide:message-square-text"
                    className="step-icon"
                    height={24}
                    width={24}
                    color={selectedFlow == "sms" ? "#0034bb" : "#212121"}
                  />
                  <div className="text">
                    <p className="heading">SMS Text Message</p>
                    <p className="sub-text">
                      Receive a text message to your mobile device.
                    </p>
                  </div>
                </div>

                <div
                  className={`flow-card ${
                    tfaResponse?.two_factor_auth_enabled &&
                    tfaResponse?.authenticator_app_verified
                      ? "disabled"
                      : ""
                  }`}
                  style={
                    selectedFlow == "auth-app" ? { borderColor: "#0034bb" } : {}
                  }
                  onClick={() => stepSelectHandler("auth-app")}
                >
                  <Icon
                    icon="lucide:lock-keyhole"
                    className="step-icon"
                    height={24}
                    width={24}
                    color={selectedFlow == "auth-app" ? "#0034bb" : "#212121"}
                  />
                  <div className="text">
                    <p className="heading">Authenticator App</p>
                    <p className="sub-text">
                      Receive unique codes from authentication app on your device.
                    </p>
                  </div>
                </div>

                <div
                  className="flow-card"
                  style={selectedFlow == "passkey" ? { borderColor: "#0034bb" } : {}}
                  onClick={() => stepSelectHandler("passkey")}
                >
                  <Icon
                    icon="lucide:key"
                    className="step-icon"
                    height={24}
                    width={24}
                    color={selectedFlow == "passkey" ? "#0034bb" : "#212121"}
                  />
                  <div className="text">
                    <p className="heading">Setup Passkey</p>
                    <p className="sub-text">
                      Passkey is faster, easier and safer than passwords. They can't
                      be guessed, stolen or leaked.
                    </p>
                  </div>
                </div>
              </div>

              <div className="action-wrapper">
                <EcnButton className="btn-text skip-btn" onClick={navigateHandler}>
                  Skip for Now
                </EcnButton>
                <EcnButton
                  className="btn-text step-btn"
                  disabled={selectedFlow === ""}
                  endIcon={<Icon icon="lucide:arrow-right" height={24} width={24} />}
                  onClick={stepInitHandler}
                >
                  Next
                </EcnButton>
              </div>
            </div>
          </div>
        ) : activeStep === 1 ? (
          <SmsFlow setActiveStep={setActiveStep} setSelectedFlow={setSelectedFlow} />
        ) : activeStep === 2 ? (
          <AuthAppFlow
            setActiveStep={setActiveStep}
            setSelectedFlow={setSelectedFlow}
          />
        ) : activeStep === 3 ? (
          <AuthAppWithSmsFlow
            setActiveStep={setActiveStep}
            setSelectedFlow={setSelectedFlow}
          />
        ) : activeStep === 4 ? (
          <PassKeyFlow
            setActiveStep={setActiveStep}
            setSelectedFlow={setSelectedFlow}
          />
        ) : (
          <div>Something went wrong. Please reload the page and try again.</div>
        )}
      </div>
    </div>
  );
};

export default layoutOne(TwoFactorAuthentication);
