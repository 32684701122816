import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const verifyOtp = createAsyncThunk("verifyOtp", async (otpData: any) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + AUTH_BASE}/v1/keycloak/signup/verify`,
    otpData
  );
  return data;
});

const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialState: {
    loading: false,
    success: false,
    verifyOtpData: null,
    error: false,
  },
  reducers: {
    reset: (state) => {
      state.loading = false;
      state.verifyOtpData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(verifyOtp.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.verifyOtpData = action.payload;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { reset } = verifyOtpSlice.actions;

export default verifyOtpSlice.reducer;
