import React, { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import layoutOne from "../layouts/layoutOne";
import EcnInput from "../components/StyledComponents/EcnInput";
import { CircularProgress, InputAdornment } from "@mui/material";
import { Icon } from "@iconify/react";
import CheckPassword from "../components/SignUp/CheckPassword";
import EcnButton from "../components/StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { acceptInvite } from "../redux/slices/userSlices/acceptInviteSlice";
import { recruitRedirect } from "../helpers/utils";

const AcceptUser = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const { acceptInviteData, loading } = useAppSelector(
    (state: any) => state.acceptInvite
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isStrong, setIsStrong] = useState(0);

  useEffect(() => {
    if (acceptInviteData) window.location.href = `${recruitRedirect()}dashboard`;
  }, [acceptInviteData]);

  const confirmHandler = () => {
    if (password === "" || confirmPassword !== password) {
      return;
    } else {
      dispatch(
        acceptInvite({
          token,
          password,
          confirm_password: confirmPassword,
        })
      );
    }
  };

  return (
    <div className="signup">
      <div className="container">
        <div className="branding">
          <div className="old">
            <img className="old-logo" src="/logo.png" alt="" />
            <p className="sub-head">is now part of</p>
          </div>
          <div className="new">
            <img className="ecn-logo" src="/one_logo.png" alt="Elevate" />
            <p className="sub">One Financial Network</p>
          </div>
        </div>
        <div className="card">
          <h1>Set up your profile</h1>

          <div className="field">
            <label htmlFor="">Email</label>
            <EcnInput
              placeholder="Enter email address"
              className="ecn-input signin-input"
              value={email}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon icon="lucide:mail" height={20} width={20} color="grey" />
                  </InputAdornment>
                ),
              }}
            />
            <p className="email-verified">
              <Icon icon="charm:tick" /> Verified
            </p>
          </div>

          <CheckPassword
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            password={password}
            confirmPassword={confirmPassword}
            setIsStrong={setIsStrong}
          />

          {isStrong != 4 && (
            <p className="mobile-case">
              Must contain 1 uppercase,1 number, min 8 characters , special character
            </p>
          )}

          <div className="button">
            <EcnButton
              variant="contained"
              disabled={
                password === "" ||
                confirmPassword === "" ||
                confirmPassword !== password ||
                isStrong !== 4 ||
                loading
              }
              onClick={confirmHandler}
            >
              Confirm{" "}
              {loading && (
                <CircularProgress
                  sx={{ color: "white", marginLeft: "10px" }}
                  size={15}
                />
              )}
            </EcnButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default layoutOne(AcceptUser);
