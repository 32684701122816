import React from "react";
import { SnackbarProps, Snackbar } from "@mui/material";

import { styled } from "@mui/material";

/*const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#2BBA1D",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
 
  "&.notistack-MuiContent-error": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#BA0000",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-warning": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    padding: "20px 23px 16px 21px",
    minWidth: "442px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(254, 152, 0, 1)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-info": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(33, 33, 33, 1)",
      transform: "rotate(180deg)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-default": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    minWidth: "442px",
    fontWeight: "500",
    padding: "12px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
  },
}));*/

const EcnSnackbar = (props: SnackbarProps) => {
  return <Snackbar {...props}>{props.children}</Snackbar>;
};

export default EcnSnackbar;
