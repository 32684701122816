export const maskCountry = {
  af: "..............",
  al: "..............",
  dz: "..............",
  ad: "..............",
  ao: "..............",
  ag: "..............",
  ar: "..........",
  am: "........",
  aw: "..............",
  au: "..........",
  at: "..............",
  az: ".........",
  bs: "..............",
  bh: "..............",
  bd: "..............",
  bb: "..............",
  by: ".........",
  be: ".........",
  bz: "..............",
  bj: "..............",
  bt: "..............",
  bo: "..............",
  ba: "..............",
  bw: "..............",
  br: "...........",
  io: "..............",
  bn: "..............",
  bg: "..............",
  bf: "..............",
  bi: "..............",
  kh: "..............",
  cm: "..............",
  ca: "..........",
  cv: "..............",
  bq: "..............",
  cf: "..............",
  td: "..............",
  cl: "..............",
  cn: "...........",
  co: "..........",
  km: "..............",
  cd: "..............",
  cg: "..............",
  cr: "........",
  ci: "........",
  hr: "..............",
  cu: "..............",
  cw: "..............",
  cy: "........",
  cz: ".........",
  dk: "........",
  dj: "..............",
  dm: "..............",
  do: "..............",
  ec: "..............",
  eg: "..............",
  sv: "........",
  gq: "..............",
  er: "..............",
  ee: "..........",
  et: "..............",
  fj: "..............",
  fi: ".........",
  fr: ".........",
  gf: "..............",
  pf: "..............",
  ga: "..............",
  gm: "..............",
  ge: "..............",
  de: "............",
  gh: "..............",
  gr: "..............",
  gd: "..............",
  gp: "..............",
  gu: "..............",
  gt: "........",
  gn: "..............",
  gw: "..............",
  gy: "..............",
  ht: "........",
  hn: "..............",
  hk: "........",
  hu: "..............",
  is: ".......",
  in: "..........",
  id: "..............",
  ir: "..........",
  iq: "..............",
  ie: ".........",
  il: "..........",
  it: "..........",
  jm: "..............",
  jp: "..........",
  jo: "..............",
  kz: "........-..",
  ke: "..............",
  ki: "..............",
  xk: "..............",
  kw: "..............",
  kg: ".........",
  la: "..............",
  lv: "........",
  lb: "..............",
  ls: "..............",
  lr: "..............",
  ly: "..............",
  li: "..............",
  lt: "..............",
  lu: "..............",
  mo: "..............",
  mk: "..............",
  mg: "..............",
  mw: "..............",
  my: "......-....",
  mv: "..............",
  ml: "..............",
  mt: "..............",
  mh: "..............",
  mq: "..............",
  mr: "..............",
  mu: "..............",
  mx: "..........",
  fm: "..............",
  md: "......-..",
  mc: "..............",
  mn: "..............",
  me: "..............",
  ma: "..............",
  mz: "..............",
  mm: "..............",
  na: "..............",
  nr: "..............",
  np: "..............",
  nl: "..........",
  nc: "..............",
  nz: "......-....",
  ni: "..............",
  ne: "..............",
  ng: "..............",
  kp: "..............",
  no: "........",
  om: "..............",
  pk: "..........",
  pw: "..............",
  ps: "..............",
  pa: "..............",
  pg: "..............",
  py: "..............",
  pe: "..............",
  ph: "...........",
  pl: "......-...",
  pt: "..............",
  pr: "..............",
  qa: "..............",
  re: "..............",
  ro: "..............",
  ru: "........-..",
  rw: "..............",
  kn: "..............",
  lc: "..............",
  vc: "..............",
  ws: "..............",
  sm: "..............",
  st: "..............",
  sa: "..............",
  sn: "..............",
  rs: "..............",
  sc: "..............",
  sl: "..............",
  sg: "........",
  sk: "..............",
  si: "..............",
  sb: "..............",
  so: "..............",
  za: "..............",
  kr: "...........",
  ss: "..............",
  es: ".........",
  lk: "..............",
  sd: "..............",
  sr: "..............",
  sz: "..............",
  se: ".........",
  ch: ".........",
  sy: "..............",
  tw: "..............",
  tj: "..............",
  tz: "..............",
  th: "..............",
  tl: "..............",
  tg: "..............",
  to: "..............",
  tt: "..............",
  tn: "..............",
  tr: "..........",
  tm: "..............",
  tv: "..............",
  ug: "..............",
  ua: ".........",
  ae: "..............",
  gb: "..........",
  us: "..........",
  uy: "..............",
  uz: ".........",
  vu: "..............",
  va: "..........",
  ve: "..............",
  vn: "..............",
  ye: "..............",
  zm: "..............",
  zw: "..............",
};

const DEV_ENV = process.env.DEPLOYMENT_ENV;

export function isValidOrigin(url: any) {
  const validOrigins = [
    "https://talent.dev.ecndev.io",
    "https://talent.test.ecndev.io",
    "https://talent.onefinnet.com",
    "https://admin.dev.ecndev.io",
    "https://admin.test.ecndev.io",
    "https://admin.onefinnet.com",
  ];

  // Ensure the provided URL is a string
  if (typeof url !== 'string') {
    return false;
  }

  try {
    // Extract the origin from the URL
    const urlObject = new URL(url);
    const origin = urlObject.origin;

    // Check if the provided URL matches any of the valid origins
    return validOrigins.some((validOrigin) => origin === validOrigin);
  } catch (error) {
    // If URL constructor throws an error, it means the input is not a valid URL
    return false;
  }
}


export function Redirect(BASE: any) {
  let NEW_BASE = "";

  if (
    BASE === "https://api.onefinnet.com" ||
    BASE === "https://api.elevatecareernetwork.com"
  ) {
    NEW_BASE = "https://admin.onefinnet.com/signup/details";
  } else if (BASE === "https://api.test.ecndev.io") {
    NEW_BASE = "https://admin.test.ecndev.io/signup/details";
  } else {
    NEW_BASE = "https://admin.dev.ecndev.io/signup/details";
  }
  return NEW_BASE;
}
export function RedirectForNetwork(BASE: any) {
  let NEW_BASE = "";

  if (
    BASE === "https://api.onefinnet.com" ||
    BASE === "https://api.elevatecareernetwork.com"
  ) {
    NEW_BASE = "https://onefinnet.com/signup/details";
  } else if (BASE === "https://api.test.ecndev.io") {
    NEW_BASE = "https://www.test.ecndev.io/signup/details";
  } else {
    NEW_BASE = "https://www.dev.ecndev.io/signup/details";
  }
  return NEW_BASE;
}

export function loginRedirectNetwork(BASE: any) {
  let NEW_BASE = "";

  if (
    BASE === "https://api.onefinnet.com" ||
    BASE === "https://api.elevatecareernetwork.com"
  ) {
    NEW_BASE = "https://onefinnet.com/portal/events";
  } else if (BASE === "https://api.test.ecndev.io") {
    NEW_BASE = "https://www.test.ecndev.io/portal/events";
  } else {
    NEW_BASE = "https://www.dev.ecndev.io/portal/events";
  }
  return NEW_BASE;
}

export function loginFirm(BASE: any) {
  let NEW_BASE = "";

  if (
    BASE === "https://api.onefinnet.com" ||
    BASE === "https://api.elevatecareernetwork.com"
  ) {
    NEW_BASE = "https://admin.onefinnet.com/";
  } else if (BASE === "https://api.test.ecndev.io") {
    NEW_BASE = "https://admin.test.ecndev.io/";
  } else {
    NEW_BASE = "https://admin.dev.ecndev.io/";
  }
  return NEW_BASE;
}
