import React, { Fragment, useState } from "react";

import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import EcnButton from "../StyledComponents/EcnButton";
import MobileInput from "./Sub/MobileInput";
import MobileVerification from "./Sub/MobileVerification";
import SuccessStep from "./Sub/SuccessStep";
import { Icon } from "@iconify/react";
import { useAppDispatch } from "../../redux/hooks";
import { verifyMobileReset } from "../../redux/slices/tfaSlices/tfaVerifyMobileSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 10,
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E7E7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  "&.MuiLinearProgress-determinate": {
    backgroundColor: "#E9E7E7",
  },
}));

interface FlowProps {
  setSelectedFlow: React.Dispatch<React.SetStateAction<string>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const SmsFlow = ({ setSelectedFlow, setActiveStep }: FlowProps) => {
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");

  const leaveFlowHandler = () => {
    setSelectedFlow("");
    setActiveStep(0);
    dispatch(verifyMobileReset());
  };
  return (
    <div className="sms-flow">
      <div className="card">
        {step <= 2 && (
          <Fragment>
            <BorderLinearProgress
              variant="determinate"
              color="primary"
              value={(step / 2) * 100}
              className="progress-bar"
            />
            <div className="head">
              <EcnButton
                className="btn-text back-btn"
                onClick={leaveFlowHandler}
                startIcon={
                  <Icon
                    icon="lucide:arrow-left"
                    height={24}
                    width={24}
                    color="rgba(33, 33, 33, 0.8)"
                  />
                }
              >
                SMS Text Message
              </EcnButton>
              <p className="step-count">{step}/2</p>
            </div>
          </Fragment>
        )}

        {step === 1 ? (
          <MobileInput mobile={mobile} setMobile={setMobile} setStep={setStep} />
        ) : step === 2 ? (
          <MobileVerification mobile={mobile} setStep={setStep} />
        ) : (
          <SuccessStep />
        )}
      </div>
    </div>
  );
};

export default SmsFlow;
