import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaGenerateQr = createAsyncThunk(
  "tfaGenerateQr",
  async (email: string) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/generateAuthenicatorQRCode`,
      {
        enterprise: true,
        email: email,
      }
    );

    return data;
  }
);

const tfaGenerateQrSlice = createSlice({
  name: "tfaGenerateQr",
  initialState: {
    loading: false,
    tfaGeneratedQr: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tfaGenerateQr.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tfaGenerateQr.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaGeneratedQr = action.payload;
    });
    builder.addCase(tfaGenerateQr.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export default tfaGenerateQrSlice.reducer;
