import tfaAddMobileSlice from "./tfaAddMobileSlice";
import tfaAuthAppLoginSlice from "./tfaAuthAppLoginSlice";
import tfaEnableSlice from "./tfaEnableSlice";
import tfaGenerateQrSlice from "./tfaGenerateQrSlice";
import tfaSendMobileOtpSlice from "./tfaSendMobileOtpSlice";
import tfaSmsLoginSlice from "./tfaSmsLoginSlice";
import tfaStatusSlice from "./tfaStatusSlice";
import tfaVerifyAuthAppSlice from "./tfaVerifyAuthAppSlice";
import tfaVerifyMobileSlice from "./tfaVerifyMobileSlice";
import tfaPassKeyNameChangeSlice from "./tfaPasskeyNameChangeSlice";

export const allTfaSlices = {
  userTfaStatus: tfaStatusSlice,
  tfaAddMobile: tfaAddMobileSlice,
  tfaVerifyMobile: tfaVerifyMobileSlice,
  tfaEnable: tfaEnableSlice,
  tfaGenerateQr: tfaGenerateQrSlice,
  tfaSendMobileOtp: tfaSendMobileOtpSlice,
  tfaVerifyAuthApp: tfaVerifyAuthAppSlice,
  tfaSmsLogin: tfaSmsLoginSlice,
  tfaAuthAppLogin: tfaAuthAppLoginSlice,
  tfaPasskeyNameChange: tfaPassKeyNameChangeSlice,
};
