import React from "react";
import "./App.css";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Signin from "./pages/Signin";
import ForgotPassword from "./pages/ForgotPassword";
import TwoFactorAuthentication from "./pages/TwoFactorAuthentication";
import Dashboard from "./pages/Dashboard";
import Signup from "./pages/Signup";
import SignUpDetails from "./pages/SignUpDetails";
import AcceptUser from "./pages/AcceptUser";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/signin" />} />

        <Route path="/signin" element={<Signin />} />
        <Route path="/accept-invite" element={<AcceptUser />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/tfa" element={<TwoFactorAuthentication />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/details" element={<SignUpDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
