import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import EcnButton from "../../StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  tfaVerifyAuthApp,
  verifyAuthAppReset,
} from "../../../redux/slices/tfaSlices/tfaVerifyAuthAppSlice";
import { tfaEnable } from "../../../redux/slices/tfaSlices/tfaEnableSlice";
import { CircularProgress } from "@mui/material";

const AuthAppVerification = ({ setStep }: { setStep: any }) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { tfaAuthAppVerifyResponse, error, loading, errorData } = useAppSelector(
    (state) => state.tfaVerifyAuthApp
  );
  const { tfaEnableResponse } = useAppSelector((state) => state.tfaEnable);
  const { tfaResponse } = useAppSelector((state) => state.userTfaStatus);

  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (tfaEnableResponse) setStep((prev: number) => prev + 1);
  }, [tfaEnableResponse]);

  const backHandler = () => {
    setStep((prev: number) => prev - 1);
    dispatch(verifyAuthAppReset());
  };

  const otpChangeHandler = (otp: string) => {
    const upOtp = otp.toUpperCase();
    setOtp(upOtp);
    if (otp.length === 6)
      dispatch(
        tfaVerifyAuthApp({
          otp,
          email: userInfo?.email,
        })
      );
  };

  const enableTfaHandler = () => {
    if (tfaResponse?.two_factor_auth_enabled) {
      setStep((prev: number) => prev + 1);
    } else {
      dispatch(tfaEnable(userInfo?.email));
    }
  };

  return (
    <div className="auth-app-verification">
      <div className="auth-app-verification-container">
        <p className="mobile-heading">Enter Authentication code</p>

        <div className="field">
          <p className="num">
            Enter the 6-digit authentication code generated by your authentication
            app
          </p>
          <OTPInput
            value={otp}
            onChange={(val) => otpChangeHandler(val)}
            numInputs={6}
            renderInput={(props) => <input {...props} className="otp-input" />}
            containerStyle="otp-container"
            inputStyle={
              error
                ? {
                    background: "transparent",
                    border: "1px solid #ba0000",
                    fontSize: "1.5rem",
                  }
                : tfaAuthAppVerifyResponse
                ? {
                    background: "transparent",
                    border: "1px solid #027A48",
                    fontSize: "1.5rem",
                  }
                : { fontSize: "1.5rem" }
            }
          />
          {loading && <CircularProgress className="loader" />}
          {error &&
            (errorData?.message === "Session Expired" ? (
              <p className="error">Session Expired, Please signin again.</p>
            ) : errorData?.message === "Max Otp attempts exceeded" ? (
              <p className="error">
                Max Otp attempts exceeded, Please signin again.
              </p>
            ) : (
              <p className="error">Code entered is incorrect. Please try again</p>
            ))}
          {tfaAuthAppVerifyResponse && (
            <p className="success">OTP is verified. You are good to go</p>
          )}
        </div>

        <div className="btn-wrapper">
          <EcnButton className="btn-text back-btn" onClick={backHandler}>
            Previous
          </EcnButton>
          <EcnButton
            className="btn-contained enable-btn"
            variant="contained"
            disabled={otp.length !== 6 || !tfaAuthAppVerifyResponse}
            onClick={enableTfaHandler}
          >
            Enable 2FA
          </EcnButton>
        </div>
      </div>
    </div>
  );
};

export default AuthAppVerification;
