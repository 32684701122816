import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const userDetails = createAsyncThunk("userDetails", async () => {
  const { data } = await axios.get(`${GATEWAY_BASE + AUTH_BASE}/v1/getAccountInfo`);
  return data;
});

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {
    loading: false,
    userInfo: null as any,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(userDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(userDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default userDetailsSlice.reducer;
