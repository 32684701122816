import { Icon } from "@iconify/react";
import React from "react";
import EcnButton from "../../StyledComponents/EcnButton";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const SetupAuthApp = ({ setStep }: { setStep: any }) => {
  const nextHandler = () => {
    setStep((prev: number) => prev + 1);
  };

  const googleAuthAppHandler = () => {
    window.open(
      "https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DAndroid",
      "_blank"
    );
  };

  const microsoftAuthAppHandler = () => {
    window.open(
      "https://support.microsoft.com/en-us/account-billing/download-and-install-the-microsoft-authenticator-app-351498fc-850a-45da-b7b6-27e523b8702a",
      "_blank"
    );
  };
  return (
    <div className="setup-auth-app">
      <div className="setup-auth-app-container">
        <p className="heading">Setup Authenticator App</p>
        <p className="sub">
          You will need a two-factor authentication app to complete this process.
        </p>

        <div className="apps-wrapper">
          <div className="app">
            <img
              src={IMG_BASE + "/enterprise/ga.png"}
              onClick={googleAuthAppHandler}
              alt=""
            />
            <Icon
              icon="lucide:external-link"
              className="link-icon"
              height={24}
              width={24}
              onClick={googleAuthAppHandler}
            />
            <p className="app-name">Google Authenticator</p>
          </div>

          <div className="app">
            <img
              src={IMG_BASE + "/enterprise/ma1.png"}
              onClick={microsoftAuthAppHandler}
              alt=""
            />
            <Icon
              icon="lucide:external-link"
              className="link-icon"
              height={24}
              width={24}
              onClick={microsoftAuthAppHandler}
            />
            <p className="app-name">Microsoft Authenticator</p>
          </div>
        </div>

        <div className="btn-wrapper">
          <EcnButton className="btn-text alt-btn" onClick={nextHandler}>
            I already have an authenticator app
          </EcnButton>
          <EcnButton
            className="btn-text next-btn"
            endIcon={<Icon icon="lucide:arrow-right" height={24} width={24} />}
            onClick={nextHandler}
          >
            Next
          </EcnButton>
        </div>
      </div>
    </div>
  );
};

export default SetupAuthApp;
