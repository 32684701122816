import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import EcnButton from "../StyledComponents/EcnButton";
import {
  InputAdornment,
  IconButton,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import EcnInput from "../StyledComponents/EcnInput";

import {
  hasNumber,
  hasEightCharacters,
  hasSpecialCharacter,
  hasUpperAndLowerCase,
  strongPassword,
} from "../../helpers/Validations/BasicValidations";
import EcnPopover from "../StyledComponents/EcnPopover";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userResetPassword } from "../../redux/slices/userSlices/userResetPasswordSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnSnackbar from "../StyledComponents/EcnSnackbar";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 10,
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E7E7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  "&.MuiLinearProgress-determinate": {
    backgroundColor: "#E9E7E7",
  },
}));

const PasswordStep = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const next = searchParams.get("next");

  const {
    resetPasswordResponse,
    error: resetPasswordError,
    loading,
  } = useAppSelector((state: any) => state.resetPassword);

  const [viewError, setViewError] = useState(false);
  const [touched, setTouched] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({
    num: 0,
    text: "",
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (resetPasswordError && touched) {
      setViewError(true);
      setTouched(false);
    }
  }, [resetPasswordError, touched]);

  useEffect(() => {
    if (password) {
      let strengthCount = 0;

      if (hasEightCharacters(password)) strengthCount++;
      if (hasNumber(password)) strengthCount++;
      if (hasSpecialCharacter(password)) strengthCount++;
      if (hasUpperAndLowerCase(password)) strengthCount++;

      switch (strengthCount) {
        case 1:
          setPasswordStrength({ num: 1, text: "Bad" });
          break;
        case 2:
          setPasswordStrength({ num: 2, text: "Weak" });
          break;
        case 3:
          setPasswordStrength({ num: 3, text: "Intermediate" });
          break;
        case 4:
          setPasswordStrength({ num: 4, text: "Good" });
          break;
        default:
          setPasswordStrength({ num: 0, text: "" });
          break;
      }
    }
  }, [password]);

  useEffect(() => {
    if (resetPasswordResponse) {
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        navigate(`/signin?origin=${next}`);
      }, 3000);
    }
  }, [resetPasswordResponse]);

  const handlePopoverOpen = (event: React.ChangeEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    handlePopoverOpen(e);
  };

  const confirmPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (passwordStrength.num === 4 && e.target.value.length > 0)
      handlePopoverClose();
  };

  const resetUserPassword = () => {
    setTouched(true);
    dispatch(
      userResetPassword({
        token: token,
        new_password: password,
        enterprise: true,
      })
    );
  };

  return (
    <div className="password-step">
      <div>
        <h3>Enter New Password</h3>

        {/* <p className="email">{email}</p> */}

        <div className="all-fields">
          <div className="field">
            <label htmlFor="">Password</label>

            <EcnInput
              aria-owns={open ? "password-popover" : undefined}
              aria-haspopup="true"
              type={showPass ? "text" : "password"}
              placeholder="Enter password"
              className="ecn-input signin-input"
              value={password}
              onChange={passwordHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon="lucide:lock-keyhole"
                      height={20}
                      width={20}
                      color="black"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      <Icon
                        icon={showPass ? "lucide:eye" : "lucide:eye-off"}
                        height={24}
                        width={24}
                        color="black"
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {password !== "" ? (
              <div style={{ textAlign: "right" }}>
                <BorderLinearProgress
                  variant="determinate"
                  color={
                    [1, 2].includes(passwordStrength.num)
                      ? "error"
                      : passwordStrength.num === 3
                      ? "warning"
                      : "success"
                  }
                  value={(passwordStrength.num / 4) * 100}
                />
                <p
                  style={{
                    color: [1, 2].includes(passwordStrength.num)
                      ? "#ba0000"
                      : passwordStrength.num === 3
                      ? "#fe9800"
                      : "#027A48",
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  {passwordStrength.text}
                </p>
              </div>
            ) : null}
          </div>
          <div
            className="field"
            style={{ marginTop: passwordStrength.num === 0 ? 9 : 0 }}
          >
            <label htmlFor="">Re-Enter Password</label>

            <EcnInput
              type={showConfirmPass ? "text" : "password"}
              placeholder="Enter password"
              className="ecn-input signin-input"
              value={confirmPassword}
              onChange={confirmPasswordHandler}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon="lucide:lock-keyhole"
                      height={20}
                      width={20}
                      color="black"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    >
                      <Icon
                        icon={showConfirmPass ? "lucide:eye" : "lucide:eye-off"}
                        height={24}
                        width={24}
                        color="black"
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {confirmPassword !== "" && confirmPassword !== password ? (
              <p className="error-password">Password doesn’t match</p>
            ) : null}
          </div>
        </div>
      </div>

      {resetPasswordResponse ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <img
            src="https://ecn.blob.core.windows.net/ecn/images/Animation - 1718697197116.gif"
            style={{ width: "30px", height: "30px" }}
          />
          <p
            style={{
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "center",
              color: "rgba(34, 150, 81, 1)",
            }}
          >
            Password Reset Successful!
          </p>
        </div>
      ) : (
        <div className="action-wrapper">
          <EcnButton
            className="btn-contained signin-next_btn confirm-button"
            variant="contained"
            disabled={
              passwordStrength.num !== 4 ||
              password !== confirmPassword ||
              !strongPassword.test(password) ||
              loading
            }
            onClick={resetUserPassword}
          >
            Reset Password{" "}
            <Icon
              icon="lucide:arrow-right"
              className="right-icon"
              width={18}
              height={18}
              style={{ marginTop: "5px", marginLeft: "5px" }}
            />
            {loading && (
              <CircularProgress
                style={{ color: "#fff", marginLeft: "10px" }}
                size={15}
              />
            )}
          </EcnButton>
        </div>
      )}

      <EcnPopover
        id="password-popover"
        open={open}
        anchorEl={anchorEl}
        sx={{
          marginLeft: "85px",
          display: { xs: "none", sm: "block" },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            sx: { borderRadius: "6px", boxShadow: "none", overflow: "visible" },
          },
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <p
          style={{
            fontSize: "14px",
            color: "#212121",
            lineHeight: "26px",
            marginBottom: "6px",
          }}
        >
          Password must have:
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasEightCharacters(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasEightCharacters(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasEightCharacters(password) ? "#027A48" : "#ba0000"}
          />
          At least 8 characters
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasUpperAndLowerCase(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasUpperAndLowerCase(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasUpperAndLowerCase(password) ? "#027A48" : "#ba0000"}
          />
          Uppercase and lowercase character
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasSpecialCharacter(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasSpecialCharacter(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasSpecialCharacter(password) ? "#027A48" : "#ba0000"}
          />
          One special character (@!#$%)
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasNumber(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasNumber(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasNumber(password) ? "#027A48" : "#ba0000"}
          />
          One Number (1-9)
        </p>
      </EcnPopover>

      <EcnSnackbar
        open={viewError}
        onClose={() => setViewError(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        className="ecn-snackbar"
      >
        <Alert
          severity="error"
          onClose={() => setViewError(false)}
          iconMapping={{
            success: <Icon icon="lucide:x-circle" />,
          }}
        >
          <AlertTitle className="title-text">Error Occurred</AlertTitle>
          Something's not right. Retry or wait a moment.
        </Alert>
      </EcnSnackbar>
    </div>
  );
};

export default PasswordStep;

const popoverOption = {
  fontSize: "14px",
  lineHeight: "26px",
  display: "flex",
  alignItems: "center",
  gap: "4px",
};
