import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaSendMobileOtp = createAsyncThunk(
  "tfaSendMobileOtp",
  async (session_id: string) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/tfa/send-otp`,
      {
        session_id: session_id,
      }
    );

    return data;
  }
);

const tfaSendMobileOtpSlice = createSlice({
  name: "tfaSendMobileOtp",
  initialState: {
    loading: false,
    tfaSendOtpResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tfaSendMobileOtp.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tfaSendMobileOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaSendOtpResponse = action.payload;
    });
    builder.addCase(tfaSendMobileOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export default tfaSendMobileOtpSlice.reducer;
