import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import EcnButton from "../../StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { tfaSendMobileOtp } from "../../../redux/slices/tfaSlices/tfaSendMobileOtpSlice";
import {
  tfaVerifyMobile,
  verifyMobileReset,
} from "../../../redux/slices/tfaSlices/tfaVerifyMobileSlice";
import { tfaEnable } from "../../../redux/slices/tfaSlices/tfaEnableSlice";
import { Icon } from "@iconify/react";
import { tfaAddMobile } from "../../../redux/slices/tfaSlices/tfaAddMobileSlice";
import { CircularProgress } from "@mui/material";

const MobileVerification = ({
  setStep,
  mobile,
  midStep,
}: {
  setStep: any;
  mobile: string;
  midStep?: true;
}) => {
  const dispatch = useAppDispatch();

  const { tfaMobileVerifyResponse, error, loading, errorData } = useAppSelector(
    (state) => state.tfaVerifyMobile
  );
  const { addMobileResponse } = useAppSelector((state) => state.tfaAddMobile);
  const { tfaEnableResponse } = useAppSelector((state) => state.tfaEnable);
  const { userInfo } = useAppSelector((state) => state.userDetails);

  const [otp, setOtp] = useState("");
  const [resendTime, setResendTime] = useState(0);

  useEffect(() => {
    let intervalId: any;

    if (resendTime > 0) {
      intervalId = setInterval(() => {
        setResendTime((prev) => (prev === 0 ? 0 : prev - 1));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [resendTime]);

  useEffect(() => {
    if (!midStep && tfaEnableResponse) setStep((prev: number) => prev + 1);
  }, [midStep, tfaEnableResponse]);

  const otpChangeHandler = (otp: string) => {
    const upOtp = otp.toUpperCase();
    setOtp(upOtp);
    if (otp.length === 6)
      dispatch(
        tfaVerifyMobile({
          otp,
          session_id: addMobileResponse?.session_id,
          email: userInfo?.email,
        })
      );
  };

  const resendOtpHandler = () => {
    dispatch(tfaAddMobile({ email: userInfo?.email, phone: mobile }));
    setResendTime(60);
  };

  const backHandler = () => {
    dispatch(verifyMobileReset());
    setStep((prev: number) => prev - 1);
  };

  const nextHandler = () => {
    setStep((prev: number) => prev + 1);
  };

  const enableTfaHandler = () => {
    dispatch(tfaEnable(userInfo?.email));
  };

  return (
    <div className="mobile-verification">
      <div className="mobile-verification-container">
        <p className="mobile-heading">Enter SMS OTP</p>

        <div className="field">
          <p className="num">
            Enter the 6-digit code sent on xxxxxxxxx{mobile.slice(-2)}
          </p>
          <OTPInput
            value={otp}
            onChange={(val) => otpChangeHandler(val)}
            numInputs={6}
            renderInput={(props) => <input {...props} className="otp-input" />}
            containerStyle="otp-container"
            inputStyle={
              error
                ? {
                    background: "transparent",
                    border: "1px solid #ba0000",
                    fontSize: "1.5rem",
                  }
                : tfaMobileVerifyResponse
                ? {
                    background: "transparent",
                    border: "1px solid #027A48",
                    fontSize: "1.5rem",
                  }
                : { fontSize: "1.5rem" }
            }
          />
          {loading && <CircularProgress className="loader" />}
          {error &&
            (errorData?.message === "Session Expired" ? (
              <p className="error">Session Expired, Please signin again.</p>
            ) : errorData?.message === "Max Otp attempts exceeded" ? (
              <p className="error">
                Max Otp attempts exceeded, Please signin again.
              </p>
            ) : (
              <p className="error">Code entered is incorrect. Please try again</p>
            ))}
          {tfaMobileVerifyResponse && (
            <p className="success">OTP is verified. You are good to go</p>
          )}

          {errorData?.message !== "Session Expired" &&
            errorData?.message !== "Max Otp attempts exceeded" &&
            (resendTime > 0 ? (
              <p className="resend">
                OTP sent to entered number once again.{" "}
                <span className="time">Resend in {resendTime} secs</span>
              </p>
            ) : (
              <p className="resend">
                Didn’t receive?{" "}
                <span className="resend-btn" onClick={resendOtpHandler}>
                  Resend
                </span>
              </p>
            ))}
        </div>

        <div className="btn-wrapper">
          <EcnButton className="btn-text back-btn" onClick={backHandler}>
            Previous
          </EcnButton>
          {midStep ? (
            <EcnButton
              className="btn-text"
              disabled={otp.length !== 6 || !tfaMobileVerifyResponse}
              endIcon={<Icon icon="lucide:arrow-right" height={24} width={24} />}
              onClick={nextHandler}
            >
              Next
            </EcnButton>
          ) : (
            <EcnButton
              className="btn-contained enable-btn"
              variant="contained"
              disabled={otp.length !== 6 || !tfaMobileVerifyResponse}
              onClick={enableTfaHandler}
            >
              Enable 2FA
            </EcnButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileVerification;
