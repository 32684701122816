import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const postBasicDetails = createAsyncThunk(
  "postBasicDetails",
  async (userData: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/keycloak/signup/basicDetails`,
      userData
    );
    return data;
  }
);

const postBasicDetailsSlice = createSlice({
  name: "postBasicDetails",
  initialState: {
    loading: false,
    success: false,
    basicDetailsData: null,
    error: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postBasicDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postBasicDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.basicDetailsData = action.payload;
    });
    builder.addCase(postBasicDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default postBasicDetailsSlice.reducer;
