import { useEffect, useRef, useState } from "react";

import { Icon } from "@iconify/react";
import { CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import OTPInput from "react-otp-input";
import EcnButton from "../StyledComponents/EcnButton";
import { postSignUp } from "../../redux/slices/userSlices/signUpSlice";
import { reset, verifyOtp } from "../../redux/slices/userSlices/verifyOtpSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isValidOrigin } from "../../helpers/TempData";

const OtpVerification = ({
  setStep,
  email,
  password,
}: {
  setStep: any;
  email: any;
  password: any;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const view = searchParams?.get("account-for") || "";
  const origin = searchParams?.get("origin") || "";
  let url: URL | undefined;
  if (origin?.trim() !== "") {
    url = new URL(origin);
  }

  const [otp, setOtp] = useState("");
  const [resendTime, setResendTime] = useState<number>(60);
  const [data, setData] = useState<any>();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [click, setClick] = useState(false);

  const { postSignUpData } = useAppSelector((state) => state.signUpData);
  const { verifyOtpData, error, loading } = useAppSelector(
    (state) => state.verifyOtps
  );

  useEffect(() => {
    if (postSignUpData) {
      setData(postSignUpData);
    }
  }, [postSignUpData]);

  useEffect(() => {
    if (postSignUpData && click) {
      setData(postSignUpData);
      setResendTime(60);
      setClick(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    }
  }, [postSignUpData]);

  useEffect(() => {
    if (error) {
      setIsError(error);
    }
  }, [error]);

  useEffect(() => {
    if (verifyOtpData) {
      navigate(
        `/signup/details?account-for=${view}${
          origin != "" ? `&origin=${origin}` : ""
        }`
      );
    }
  }, [verifyOtpData]);

  useEffect(() => {
    let intervalId: any;

    if (resendTime > 0) {
      intervalId = setInterval(() => {
        setResendTime((prev) => (prev === 0 ? 0 : prev - 1));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [resendTime]);

  const otpChangeHandler = (otp: any) => {
    setIsError(false);
    const upOtp = otp.toUpperCase();
    setOtp(upOtp);
  };

  const verifyHandler = () => {
    dispatch(
      verifyOtp({
        email: email,
        otp: otp,
        session_id: data?.session_id,
      })
    );
  };

  const resendOtpHandler = () => {
    setClick(true);
    dispatch(
      postSignUp({
        email: email,
        password: password,
        enterprise: view === "firm" ? true : false,
      })
    );
  };

  const NotYourEmailHandler = () => {
    setStep(1);
    dispatch(reset());
  };

  return (
    <div className="otp-verification">
      <h2 className="title">Verify that it is you</h2>
      <div className="field">
        <p className="sent">
          {email}
          <span onClick={NotYourEmailHandler}> not your email ?</span>{" "}
        </p>
        <p className="num">Enter the 6-digit code sent to email</p>

        {error && (
          <p
            style={{
              color: "#BA0000",
              fontSize: "16px",
              fontWeight: "400",
              textAlign: "center",
              marginBottom: "10px",
            }}
            className="desktop-error"
          >
            Wrong code, please try again
          </p>
        )}

        <OTPInput
          value={otp}
          onChange={(val) => otpChangeHandler(val)}
          numInputs={6}
          renderInput={(props, index) => (
            <input
              {...props}
              className={`otp-input ${
                otp.length > index ? "data-entered" : ""
              }`}
            />
          )}
          containerStyle="otp-container"
          inputStyle={
            error
              ? {
                  background: "transparent",
                  border: "1px solid #ba0000",
                  fontSize: "1.5rem",
                }
              : verifyOtpData
              ? {
                  background: "transparent",
                  border: "1px solid #027A48",
                  fontSize: "1.5rem",
                }
              : {
                  fontSize: "1.5rem",
                }
          }
        />

        {/* <div className="mobile-error-container">
          {error && (
            <p className="mobile-error">Wrong code, please try again</p>
          )}
        </div> */}

        <div className="resend-container">
          <p className="resend">
            Didn’t receive OTP ?{" "}
            {resendTime > 0 ? (
              <span>Resend in {resendTime} s</span>
            ) : (
              <span onClick={resendOtpHandler}>Resend</span>
            )}
          </p>
          <p className="resend"></p>
        </div>

        {isSuccess && <p className="otp-sent">OTP sent successfully!</p>}

        <div className="button">
          <EcnButton
            variant="contained"
            onClick={verifyHandler}
            disabled={otp.length != 6 || loading}
          >
            {" "}
            Verify{" "}
            {!loading && (
              <Icon
                icon="lucide:arrow-right"
                className="right-icon"
                width={18}
                height={18}
              />
            )}
            {loading && (
              <CircularProgress
                sx={{ color: "white", marginLeft: "5px" }}
                size={15}
              />
            )}
          </EcnButton>
        </div>

        {/* {loading && <CircularProgress className="loader" />} */}
      </div>
    </div>
  );
};

export default OtpVerification;
