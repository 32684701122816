import React, { Fragment, useState } from "react";
import EcnButton from "../../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import axios from "../../../redux/axiosInterceptor";
import {
  arrayBufferToUrlSafeBase64,
  base64ToArrayBuffer,
} from "../../../helpers/passkeyFunctions";
import { useAppSelector } from "../../../redux/hooks";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";
const BASE = GATEWAY_BASE + AUTH_BASE;

const PasskeySetup = ({
  step,
  setStep,
  setCredId,
}: {
  step: number;
  setStep: any;
  setCredId: any;
}) => {
  const { userInfo } = useAppSelector((state) => state.userDetails);

  const [error, setError] = useState("");

  const isPasskeySupported = () => "PublicKeyCredential" in window;

  const passkeyRegistrationHandler = async () => {
    try {
      setError("");

      const startResponse = await axios.post(`${BASE}/v1/startPasskeyRegistration`, {
        email: userInfo?.email,
        enterprise: true,
        rpid: window.location.hostname,
        rporigin: window.location.origin,
      });
      let publicKeyCredentialCreationOptions = startResponse.data.publicKey;

      // Convert the challenge and user ID from URL-safe base64 to ArrayBuffer
      publicKeyCredentialCreationOptions.challenge = base64ToArrayBuffer(
        publicKeyCredentialCreationOptions.challenge
      );
      publicKeyCredentialCreationOptions.user.id = base64ToArrayBuffer(
        publicKeyCredentialCreationOptions.user.id
      );

      // Use platform authenticator and discoverable credential
      publicKeyCredentialCreationOptions.authenticatorSelection = {
        authenticatorAttachment: "platform",
        requireResidentKey: true,
      };

      const credential: any = await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions,
      });

      setCredId(credential.id);

      const attestationResponseData = {
        id: credential.id,
        type: credential.type,
        rawId: arrayBufferToUrlSafeBase64(credential.rawId),
        response: {
          clientDataJSON: arrayBufferToUrlSafeBase64(
            credential.response.clientDataJSON
          ),
          attestationObject: arrayBufferToUrlSafeBase64(
            credential.response.attestationObject
          ),
        },
        email: userInfo?.email,
        enterprise: true,
        rpid: window.location.hostname,
        rporigin: window.location.origin,
      };

      await axios.post(
        `${BASE}/v1/completePasskeyRegistration`,
        attestationResponseData
      );

      setStep(2);
    } catch (err) {
      console.error(err);
      setError("Registration failed. Please try again.");
    }
  };
  return (
    <div className="passkey-setup">
      <Icon
        icon="material-symbols:passkey"
        height={42}
        width={42}
        className="passkey-icon"
      />
      <p className="heading">Configure Passkey</p>

      <p className="sub">
        Passkeys can be used for sign-in as a simple and secure alternative to your
        password and two-factor credentials
      </p>

      {isPasskeySupported() ? (
        <Fragment>
          <div className="divider" />

          <p className="support-text">
            Your device supports passkeys. It is a password replacement that
            validates your identity using touch, facial recognition, a device
            password or Pin
          </p>

          {error && (
            <p className="error-text">
              <Icon icon="lucide:shield-x" height={24} width={24} strokeWidth={2} />
              Passkey Registration Failed
            </p>
          )}

          <div className="btn-wrapper">
            <EcnButton className="btn-text back-btn">Cancel</EcnButton>
            <EcnButton
              className="btn-contained"
              variant="contained"
              onClick={passkeyRegistrationHandler}
            >
              Add Passkey
            </EcnButton>
          </div>
        </Fragment>
      ) : (
        <div className="support-issue">
          <Icon
            icon="lucide:alert-triangle"
            height={24}
            width={24}
            strokeWidth={2}
          />
          <p className="warning-text">
            Your device doesn't support passkey authentication. Please try other
            methods.
          </p>
        </div>
      )}
    </div>
  );
};

export default PasskeySetup;
