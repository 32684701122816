import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaPassKeyNameChange = createAsyncThunk(
  "tfaPassKeyNameChange",
  async (userData: any) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/addNametoPasskey`,
      {
        id: userData.credId,
        email: userData.email,
        enterprise: true,
        name: userData.name,
      }
    );

    return data;
  }
);

const tfaPassKeyNameChangeSlice = createSlice({
  name: "tfaPassKeyNameChange",
  initialState: {
    loading: false,
    tfaPasskeyNameChangeResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tfaPassKeyNameChange.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tfaPassKeyNameChange.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaPasskeyNameChangeResponse = action.payload;
    });
    builder.addCase(tfaPassKeyNameChange.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export default tfaPassKeyNameChangeSlice.reducer;
