import React, { useEffect } from "react";
import EcnButton from "../components/StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { userSignout } from "../redux/slices/userSlices/userSignoutSlice";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { success } = useAppSelector((state) => state.signOut);

  useEffect(() => {
    if (success) {
      window.location.href = "/";
    }
  }, [success]);
  return (
    <div style={{textAlign: "center", marginTop: "200px"}}>
      <h1>COngrats</h1>
      <p>You've Successfully logged In</p>
      <EcnButton
        variant="contained"
        className="btn-contained"
        onClick={() => dispatch(userSignout(userInfo?.email))}
      >
        Logout
      </EcnButton>
    </div>
  );
};

export default Dashboard;
