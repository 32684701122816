import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnButton from "../../StyledComponents/EcnButton";
import { useAppSelector } from "../../../redux/hooks";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const SuccessStep = ({ passwordSuccess }: { passwordSuccess?: boolean }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("next");

  const { tfaResponse } = useAppSelector((state) => state.userTfaStatus);

  const [timeRemaining, setTimeRemaining] = useState(5);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (passwordSuccess) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => {
          if (prevTime <= 1) {
            // Time's up, clear interval and navigate
            clearInterval(timer);
            navigate(`/signin?origin=${redirectUrl}`);
            return 0; // Optionally reset or keep at 1 to avoid negative display
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer); // Use clearInterval for intervals
    };
  }, [passwordSuccess, navigate]);

  const navigateHandler = () => {
    if (redirectUrl) window.location.href = redirectUrl;
    else navigate(`/signin?origin=${redirectUrl}`);
  };

  const enableOtherWaysHandler = () => {
    window.location.reload();
  };
  return (
    <div className="success-step">
      <img src={IMG_BASE + "/enterprise/success_1.gif"} alt="" />
      {passwordSuccess ? (
        <h3>Password changed successfully</h3>
      ) : tfaResponse?.two_factor_auth_enabled ? (
        <h3>🎉 You have successfully added Authenticator App for TFA!</h3>
      ) : (
        <h3>🎉 You have successfully enabled Two-factor authentication!</h3>
      )}

      <div className="divider" />
      {passwordSuccess ? (
        <div className="btn-wrapper">
          <EcnButton className="btn-text back-btn">
            Redirecting in {timeRemaining}
          </EcnButton>
          <EcnButton
            className="btn-contained redirect-btn"
            variant="contained"
            onClick={() => navigate("/signin")}
          >
            Sign in
          </EcnButton>
        </div>
      ) : (
        <div className="btn-wrapper">
          <EcnButton
            className="btn-text back-btn"
            onClick={enableOtherWaysHandler}
          >
            Enable other ways
          </EcnButton>
          <EcnButton
            className="btn-contained redirect-btn"
            variant="contained"
            onClick={navigateHandler}
          >
            No, Thanks
          </EcnButton>
        </div>
      )}
    </div>
  );
};

export default SuccessStep;
