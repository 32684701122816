import { useEffect } from "react";
import Footer from "../components/Nav/Footer";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import Header from "../components/Nav/Header";

export default function layoutOne(Component: any) {
  function LayoutOne(props: any) {
    const dispatch = useAppDispatch();

    const { userInfo, error } = useAppSelector((state) => state.userDetails);
    /*useEffect(() => {
      if(!userInfo && !error) dispatch(userDetails());
    }, [userInfo, error]);*/
    return (
      <div className="layout">
        <div className="content">
          <div className="page">
            <div className="bg_circle-wrapper">
              <div className="circle circle-one" />
              <div className="circle circle-two" />
              <div className="circle circle-three" />
            </div>
            <div className="page-content">
              <Header />
              <Component {...props} />
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return LayoutOne;
}
