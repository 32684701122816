import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import EcnButton from "../StyledComponents/EcnButton";

const SuccessCard = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin") ?? "";
  const detailsHandler = () => {
    navigate(`/signup/details${origin != "" ? `?origin=${origin}` : ""}`);
  };
  return (
    <div className="success-card">
      <h1>You’re in ! </h1>

      <div className="image">
        <img
          src="https://s1.ecnstatic.com/ecn/images/User authentication in mobile application-1.png"
          className="image"
        />
      </div>
      <h1 className="mobile-title">You’re in ! </h1>
      <p>
      Your email has been verified. Now enter your basic details so you can start accessing Upcoming Networking, Recruiting & Investing Events with Top Firms
      </p>

      <div className="button">
        <EcnButton variant="contained" onClick={detailsHandler}>
          Enter Details
        </EcnButton>
      </div>
    </div>
  );
};

export default SuccessCard;
