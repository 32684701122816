import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaAddMobile = createAsyncThunk(
  "tfaAddMobile",
  async (contactInfo: { email: string; phone: string }) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/addUserContactNumber`,
      {
        enterprise: true,
        email: contactInfo.email,
        contact_number: contactInfo.phone,
      }
    );

    return data;
  }
);

const tfaAddMobileSlice = createSlice({
  name: "tfaAddMobile",
  initialState: {
    loading: false,
    addMobileResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tfaAddMobile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tfaAddMobile.fulfilled, (state, action) => {
      state.loading = false;
      state.addMobileResponse = action.payload;
    });
    builder.addCase(tfaAddMobile.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export default tfaAddMobileSlice.reducer;
