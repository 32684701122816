import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaStatus = createAsyncThunk("tfaStatus", async (email: string) => {
  const { data } = await axios.post(`${GATEWAY_BASE + AUTH_BASE}/v1/listUser2FA`, {
    enterprise: true,
    email: email,
  });

  return data;
});

const tfaStatusSlice = createSlice({
  name: "tfaStatus",
  initialState: {
    loading: false,
    tfaResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tfaStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tfaStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaResponse = action.payload;
    });
    builder.addCase(tfaStatus.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export default tfaStatusSlice.reducer;
