import React, { useEffect, useState } from "react";
import EcnInput from "../components/StyledComponents/EcnInput";
import dayjs from "dayjs";

import layoutOne from "../layouts/layoutOne";

import EcnButton from "../components/StyledComponents/EcnButton";
import {
  CircularProgress,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { correctEmail } from "../helpers/Validations/BasicValidations";
import { postBasicDetails } from "../redux/slices/userSlices/postBasicDetailsSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  isValidOrigin,
  Redirect,
  RedirectForNetwork,
} from "../helpers/TempData";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";

import { Dayjs } from "dayjs";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;

const useStyles = makeStyles((theme) => ({
  rotate: {
    transition: "transform 0.3s ease",
    transform: "rotate(0deg)",
    marginRight: "10px",
  },
  rotateOpen: {
    transform: "rotate(180deg)",
    marginRight: "10px",
  },
}));

const SignUpDetails = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { userInfo, error: userError } = useAppSelector(
    (state) => state.userDetails
  );

  const { basicDetailsData, loading } = useAppSelector(
    (state) => state.postBasicDetails
  );

  const allowedDomains = ["gmail.com", "yahoo.com", "microsoft.com"];

  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin") || "";
  const view = searchParams.get("account-for") || "";
  let url: URL | undefined;
  if (origin?.trim() !== "") {
    url = new URL(origin);
  }
  const [data, setData] = useState<any>({
    birthday: null,
    enterprise: false,
    personal_email: "",
  });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [selectedValue, setSelectedValue] = useState(0);
  const [click, setClick] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [maxDate] = useState(dayjs());
  const [askPerEmail, setAskPerSonalEmail] = useState(false);
  const [error, setError] = useState({
    status: false,
    emailError: false,
    message: "",
  });

  useEffect(() => {
    if (basicDetailsData && click) {
      if (view === "firm") {
        window.location.href = Redirect(GATEWAY_BASE);
      } else if (view != "firm") {
        window.location.href = RedirectForNetwork(GATEWAY_BASE);
      } else if (basicDetailsData && origin === "" && click) {
        navigate("/dashboard");
      }
    }
  }, [basicDetailsData]);

  useEffect(() => {
    dispatch(userDetails());
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (isAllowedDomain(userInfo?.email)) {
        setAskPerSonalEmail(false);
        // Logic to not ask for personal email
      } else {
        setAskPerSonalEmail(true);
        // Logic to ask for personal email
      }
    }
  }, [userInfo]);

  const isAllowedDomain = (email: any) => {
    const domain = email?.split("@")[1];
    return allowedDomains?.includes(domain);
  };

  const inputHandler = (e: any, type: string) => {
    const { value } = e.target;
    let isValid = false;
    setIsValidEmail(isValid);
    if (type === "personal_email" && !correctEmail?.test(value)) {
      isValid = true;
    }
    setIsValidEmail(isValid);
    setData((prevData: any) => ({
      ...prevData,
      [type]: value,
    }));
  };

  const genderHandler = (event: any) => {
    setSelectedValue(event.target.value);
    setData({
      ...data,
      gender: event.target.value,
    });
  };

  const birthdayHandler = (value: Dayjs | null) => {
    try {
      if (!value || !value.isValid()) {
        throw new Error("Invalid date");
      }

      const date = value.toDate(); // Convert Dayjs to native Date object
      const minYear = 1900; // Specify the minimum year allowed

      // Check if the selected year is less than the minimum year
      if (date.getFullYear() < minYear) {
        setError({
          status: true,
          emailError: false,
          message: ``,
        });
        return;
      }

      setError({
        status: false,
        emailError: false,
        message: "",
      });

      const birthdayDate = date;
      const currentDate = new Date();
      const ageInMillis = currentDate.getTime() - birthdayDate.getTime();

      // Convert milliseconds to years
      const msPerYear = 1000 * 60 * 60 * 24 * 365.25; // accounting for leap years
      const ageInYears = ageInMillis / msPerYear;

      const age = Number(ageInYears.toFixed(2));

      if (age <= 14) {
        setError({
          status: true,
          emailError: false,
          message: "You must be 14 years or older to use this service",
        });
      } else {
        setData({
          ...data,
          birthday: date.toISOString(),
        });
      }
    } catch (error) {
      setError({
        status: true,
        emailError: false,
        message: "An error occurred",
      });
    }
  };

  const nextHandler = () => {
    setClick(true);
    dispatch(
      postBasicDetails({ ...data, enterprise: view === "firm" ? true : false })
    );
  };

  const enterprisedNextHandler = () => {
    setClick(true);
    dispatch(
      postBasicDetails({ ...data, enterprise: view === "firm" ? true : false })
    );
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  console.log("userInfo", data);

  return (
    <div className="signup-details">
      <div className="card">
        <h2 className="title">Basic Details</h2>
        <div className="row">
          <p>First Name*</p>
          <EcnInput
            placeholder="Enter first name "
            className="input-text"
            onChange={(e) => inputHandler(e, "first_name")}
            inputProps={{
              maxLength: 35, // Enforce max length
            }}
          />
        </div>
        <div className="row">
          <p>Last Name* </p>
          <EcnInput
            placeholder="Enter last name  "
            className="input-text"
            onChange={(e) => inputHandler(e, "last_name")}
            inputProps={{
              maxLength: 35, // Enforce max length
            }}
          />
        </div>
        {view === "networking" && (
          <div className="row">
            <p>Date of Birth*</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                onChange={(e: any) => birthdayHandler(e)}
                maxDate={maxDate}
                className="input-text"
                slots={{
                  openPickerIcon: CustomOpenPickerIcon,
                }}
              />
            </LocalizationProvider>
            {error.status && (
              <p style={{ color: "rgba(186, 0, 0, 1)", marginTop: "4px" }}>
                {error?.message}
              </p>
            )}
          </div>
        )}

        <div className="row">
          <p>Gender*</p>

          <Select
            size="small"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            className="select-text"
            variant="standard"
            displayEmpty={true}
            disableUnderline
            value={selectedValue}
            onChange={genderHandler}
            IconComponent={(props) => (
              <Icon
                {...props}
                className={`${classes.rotate} ${open ? classes.rotateOpen : ""
                  }`}
                icon="lucide:chevron-down"
                width={20}
                height={20}
              />
            )}
            renderValue={(value) => (
              <span
                className={value ? "selected-value" : "placeholder-text"}
                style={{ fontWeight: "Plus Jakarta Sans" }}
              >
                {value
                  ? genderOptions.find((option) => option.value === value)
                    ?.label
                  : "Select Gender"}
              </span>
            )}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },

              PaperProps: {
                sx: {
                  maxHeight: 300,
                  maxWidth: "600px",
                  boxShadow: 1,
                  padding: "0px 10px",
                  marginTop: "3px",
                },
              },
            }}
            input={
              <OutlinedInput
                sx={{
                  "& fieldset": {
                    border: "1.5px solid rgba(33, 33, 33, 0.20) !important",
                  },
                  "&:hover fieldset": {
                    border: "1.7px solid  rgba(0, 52, 187, 0.70) !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1.7px solid rgba(0, 52, 187, 0.70) !important",
                  },
                }}
              />
            }
          >
            {genderOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={
                  data.gender === option.value
                    ? {
                      background: "rgba(0, 52, 187, 0.08)!important",
                      borderRadius: "9px",
                      marginBottom: "5px",
                      fontSize: "14px !important",
                      fontWeight: "400",
                      color: "#0034bb !important",
                      padding: "8px 15px",
                    }
                    : {
                      background: "#fff !important",
                      marginBottom: "5px",
                      fontSize: "14px !important",
                      color: "rgba(33,33,33,.7)",
                      fontWeight: "400",
                      padding: "8px 15px",
                      "&:hover": {
                        backgroundColor: "rgba(0, 52, 187, 0.08) !important",
                        color: "rgba(0, 52, 187, 1)",
                        borderRadius: "9px",
                      },
                    }
                }
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        {view === "networking" && askPerEmail && (
          <div className="row">
            <p>
              Alternate Email *{" "}
              {/* <Tooltip
                title="Please enter your personal email account (e.g., Gmail)."
                arrow
              >
                <Icon icon="lucide:info" />
              </Tooltip>{" "} */}
            </p>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "10px",
                opacity: "0.7",
              }}
            >
              You can use your educational or work email as your alternate.
            </p>
            <EcnInput
              placeholder="Enter personal email  "
              className="input-text"
              onChange={(e) => inputHandler(e, "personal_email")}
            />
            {isValidEmail && (
              <p style={{ color: "rgba(186, 0, 0, 1)", marginTop: "4px" }}>
                Invalid email address
              </p>
            )}
            {error.emailError && (
              <p style={{ color: "rgba(186, 0, 0, 1)", marginTop: "4px" }}>
                {error?.message}
              </p>
            )}
          </div>
        )}

        <div className="button">
          {view === "networking" ? (
            <EcnButton
              variant="contained"
              disabled={
                askPerEmail
                  ? data.birthday === null ||
                  data.first_name === "" ||
                  data.gender === "" ||
                  data.last_name === "" ||
                  data.personal_email === "" ||
                  isValidEmail ||
                  error?.status ||
                  loading
                  : data.birthday === null ||
                  data.first_name === "" ||
                  data.gender === "" ||
                  data.last_name === "" ||
                  loading ||
                  error?.status
              }
              onClick={nextHandler}
            >
              {" "}
              Next{" "}
              {!loading && (
                <Icon
                  icon="lucide:arrow-right"
                  className="right-icon"
                  width={18}
                  height={18}
                />
              )}
              {loading && (
                <CircularProgress
                  sx={{ color: "white", marginLeft: "5px" }}
                  size={15}
                />
              )}
            </EcnButton>
          ) : (
            <EcnButton
              variant="contained"
              disabled={
                data.first_name === "" ||
                data.gender === "" ||
                data.last_name === "" ||
                loading
              }
              onClick={enterprisedNextHandler}
            >
              Next{" "}
              {!loading && (
                <Icon
                  icon="lucide:arrow-right"
                  className="right-icon"
                  width={18}
                  height={18}
                />
              )}
              {loading && (
                <CircularProgress
                  sx={{ color: "white", marginLeft: "5px" }}
                  size={15}
                />
              )}
            </EcnButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default layoutOne(SignUpDetails);

const genderOptions = [
  { value: 1, label: "Male (He/Him)" },
  { value: 2, label: "Female (She/Her)" },
  { value: 3, label: "Non-Binary (Them/They)" },
  { value: 4, label: "Other" },
];
const CustomOpenPickerIcon = () => (
  <Icon
    icon="lucide:calendar"
    width={20}
    height={20}
    color="rgba(211, 211, 211, 1)"
  />
);
