import { useEffect, useState } from "react";
import EcnButton from "../../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import PhoneInput from "react-phone-input-2";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { tfaAddMobile } from "../../../redux/slices/tfaSlices/tfaAddMobileSlice";
import EcnSnackbar from "../../StyledComponents/EcnSnackbar";
import { Alert, AlertTitle } from "@mui/material";
import { maskCountry } from "../../../helpers/TempData";

const MobileInput = ({
  setStep,
  mobile,
  setMobile,
}: {
  setStep: any;
  mobile: string;
  setMobile: any;
}) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { addMobileResponse, error } = useAppSelector((state) => state.tfaAddMobile);

  const [touched, setTouched] = useState(false);
  const [viewError, setViewError] = useState(false);
  const [mobileError, setMobileError] = useState(false);

  useEffect(() => {
    if (error && touched) {
      setViewError(true);
      setTouched(false);
    }
  }, [error, touched]);

  useEffect(() => {
    if (addMobileResponse && !error && touched) {
      setStep((prev: number) => prev + 1);
      setTouched(false);
    }
  }, [addMobileResponse, touched, error]);

  const changeHandler = (val: string, country: any, e: any, formatVal: string) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    setMobile(formatVal);
  };

  const nextHandler = () => {
    dispatch(tfaAddMobile({ email: userInfo?.email, phone: mobile }));
    setTouched(true);
  };

  return (
    <div className="mobile-input">
      <div className="mobile-input-container">
        <p className="mobile-heading">Enter Mobile Number</p>
        <PhoneInput
          country="us"
          preferredCountries={["gb", "fr", "es", "it", "cn", "in", "us", "ca"]}
          containerClass="mobile-input"
          specialLabel=""
          placeholder="Type your mobile number"
          masks={maskCountry}
          isValid={(inputNumber, country, countries) => {
            const valid = countries.some((country: any) => {
              return inputNumber.startsWith(country.dialCode);
            });
            if (!valid) setMobileError(true);
            return true;
          }}
          value={mobile}
          onChange={(val, country, e, formatVal) =>
            changeHandler(val, country, e, formatVal)
          }
        />

        <div className="btn-wrapper">
          <EcnButton
            className="btn-text"
            endIcon={<Icon icon="lucide:arrow-right" height={24} width={24} />}
            onClick={nextHandler}
          >
            Next
          </EcnButton>
        </div>
      </div>

      <EcnSnackbar
        open={viewError}
        onClose={() => setViewError(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        className="ecn-snackbar"
      >
        <Alert
          severity="error"
          onClose={() => setViewError(false)}
          iconMapping={{
            success: <Icon icon="lucide:x-circle" />,
          }}
        >
          <AlertTitle className="title-text">Error Occurred</AlertTitle>
          Something's not right. Retry or wait a moment.
        </Alert>
      </EcnSnackbar>
    </div>
  );
};

export default MobileInput;
