import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaVerifyAuthApp = createAsyncThunk(
  "tfaVerifyAuthApp",
  async (contactInfo: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + AUTH_BASE}/v1/verifyAuthenicatorQRCode`,
        {
          enterprise: true,
          email: contactInfo.email,
          totp: contactInfo.otp,
        }
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tfaVerifyAuthAppSlice = createSlice({
  name: "tfaVerifyAuthApp",
  initialState: {
    loading: false,
    tfaAuthAppVerifyResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {
    verifyAuthAppReset: (state) => {
      state.loading = false;
      state.tfaAuthAppVerifyResponse = null;
      state.errorData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tfaVerifyAuthApp.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.tfaAuthAppVerifyResponse = null;
    });
    builder.addCase(tfaVerifyAuthApp.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaAuthAppVerifyResponse = action.payload;
    });
    builder.addCase(tfaVerifyAuthApp.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export const { verifyAuthAppReset } = tfaVerifyAuthAppSlice.actions;

export default tfaVerifyAuthAppSlice.reducer;
