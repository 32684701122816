import { useSearchParams } from "react-router-dom";

import PasswordStep from "../components/ForgotPassword/PasswordStep";
import EmailStep from "../components/ForgotPassword/EmailStep";

import layoutOne from "../layouts/layoutOne";
import { useAppSelector } from "../redux/hooks";
import SuccessStep from "../components/Tfa/Sub/SuccessStep";

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const { resetPasswordResponse } = useAppSelector(
    (state: any) => state.resetPassword
  );
  return (
    <div className="forgot-password">
      <div className="container">
        <div className="card">
          {token ? (
            // resetPasswordResponse ? (
            //   <SuccessStep passwordSuccess={true} />
            // ) : (
              <PasswordStep />
            
          ) : (
            <EmailStep />
          )}
        </div>
      </div>
    </div>
  );
};

export default layoutOne(ForgotPassword);
