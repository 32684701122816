import React, { Fragment, useState } from "react";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";

import PasskeySetup from "./Sub/PasskeySetup";
import PasskeySuccessStep from "./Sub/PasskeySuccessStep";

interface FlowProps {
  setSelectedFlow: React.Dispatch<React.SetStateAction<string>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const PassKeyFlow = ({ setSelectedFlow, setActiveStep }: FlowProps) => {
  const [step, setStep] = useState(1);
  const [credId, setCredId] = useState("");

  const leaveFlowHandler = () => {
    setSelectedFlow("");
    setActiveStep(0);
  };
  return (
    <div className="passkey-flow">
      <div className="card">
        {step === 1 ? (
          <Fragment>
            <div className="head passkey-head">
              <EcnButton
                className="btn-text back-btn"
                onClick={leaveFlowHandler}
                startIcon={
                  <Icon
                    icon="lucide:arrow-left"
                    height={24}
                    width={24}
                    color="rgba(33, 33, 33, 0.8)"
                  />
                }
              >
                PassKey
              </EcnButton>
            </div>
          </Fragment>
        ) : null}

        {step === 1 ? (
          <PasskeySetup step={step} setStep={setStep} setCredId={setCredId} />
        ) : (
          <PasskeySuccessStep credId={credId} />
        )}
      </div>
    </div>
  );
};

export default PassKeyFlow;
