import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaSmsLogin = createAsyncThunk(
  "tfaSmsLogin",
  async (contactInfo: any, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `${GATEWAY_BASE + AUTH_BASE}/v1/tfa/verify-otp`,
        {
          session_id: contactInfo.session_id,
          otp: contactInfo.otp,
        }
      );

      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tfaSmsLoginSlice = createSlice({
  name: "tfaSmsLogin",
  initialState: {
    loading: false,
    tfaSmsLoginVerifyResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {
    smsLoginReset: (state) => {
      state.loading = false;
      state.tfaSmsLoginVerifyResponse = null;
      state.errorData = null;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tfaSmsLogin.pending, (state, action) => {
      state.loading = true;
      state.error = false;
      state.tfaSmsLoginVerifyResponse = null;
    });
    builder.addCase(tfaSmsLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaSmsLoginVerifyResponse = action.payload;
    });
    builder.addCase(tfaSmsLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export const { smsLoginReset } = tfaSmsLoginSlice.actions;

export default tfaSmsLoginSlice.reducer;
