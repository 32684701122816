import { useState } from "react";
import { useAppSelector } from "../../../redux/hooks";

import EcnButton from "../../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import EcnSnackbar from "../../StyledComponents/EcnSnackbar";
import { Alert, AlertTitle } from "@mui/material";

const AuthAppQr = ({ setStep }: { setStep: any }) => {
  const { tfaGeneratedQr } = useAppSelector((state) => state.tfaGenerateQr);

  const [copied, setCopied] = useState(false);

  const copyToClipboardHandler = () => {
    navigator.clipboard.writeText(tfaGeneratedQr?.secret_key);
    setCopied(true);
  };

  const nextHandler = () => {
    setStep((prev: number) => prev + 1);
  };

  const backHandler = () => {
    setStep((prev: number) => prev - 1);
  };
  return (
    <div className="auth-app-qr">
      <div className="auth-app-qr-container">
        <p className="heading">Scan QR Code</p>
        <img
          src={`data:image/png;base64,${tfaGeneratedQr?.qr_code_image}`}
          alt=""
          className="qr"
        />
        <p className="sub">
          If you have any problems with scanning enter this code manually into the
          app:
        </p>

        <div className="copy-box">
          <p className="copy-key">{tfaGeneratedQr?.secret_key}</p>
          <Icon
            icon="ph:copy"
            className="copy-icon"
            height={28}
            width={28}
            color="#212121"
            onClick={copyToClipboardHandler}
          />
        </div>

        <div className="btn-wrapper">
          <EcnButton className="btn-text back-btn" onClick={backHandler}>
            Previous
          </EcnButton>
          <EcnButton
            className="btn-text next-btn"
            endIcon={<Icon icon="lucide:arrow-right" height={24} width={24} />}
            onClick={nextHandler}
          >
            Next
          </EcnButton>
        </div>
      </div>

      <EcnSnackbar
        open={copied}
        onClose={() => setCopied(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        className="ecn-snackbar"
      >
        <Alert
          severity="success"
          onClose={() => setCopied(false)}
          iconMapping={{
            success: <Icon icon="lucide:check-circle-2" />,
          }}
        >
          <AlertTitle className="title-text">Success</AlertTitle>
          Your code has been copied to the clipboard.
        </Alert>
      </EcnSnackbar>
    </div>
  );
};

export default AuthAppQr;
