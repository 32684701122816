import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const tfaEnable = createAsyncThunk("tfaEnable", async (email: string) => {
  const { data } = await axios.post(
    `${GATEWAY_BASE + AUTH_BASE}/v1/enableTwoFactorAuth`,
    {
      enterprise: true,
      email: email,
    }
  );

  return data;
});

const tfaEnableSlice = createSlice({
  name: "tfaEnable",
  initialState: {
    loading: false,
    tfaEnableResponse: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tfaEnable.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tfaEnable.fulfilled, (state, action) => {
      state.loading = false;
      state.tfaEnableResponse = action.payload;
    });
    builder.addCase(tfaEnable.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.payload;
    });
  },
});

export default tfaEnableSlice.reducer;
