export function recruitRedirect() {
  if (
    window.location.origin === "https://auth.dev.ecndev.io" ||
    window.location.origin === "http://localhost:3000"
  )
    return "https://talent.dev.ecndev.io/";
  else if (window.location.origin === "https://auth.test.ecndev.io")
    return "https://talent.test.ecndev.io/";
  else if (window.location.origin === "https://auth.onefinnet.com")
    return "https://talent.onefinnet.com/";
}
